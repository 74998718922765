import React from "react";

import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import Button from "../../AuthButton/Button";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../../api/apiEndpoints";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./login.css";

const Login = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [forgotPasswordpath, setForgotPasswordPath] = useState("");
  const [loginError, setLoginError] = useState(""); 

  const currentTime = new Date();
  const timeDifferenceInMinutes = currentTime.getTimezoneOffset();

  useEffect(() => {
    if (pathname === "/login") {
      setForgotPasswordPath("/forgotPassword");
    } else {
      setForgotPasswordPath("/forgotPassword");
    }
  }, [pathname]);

  const handleLogin = async (values) => {
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
      timeDifference: timeDifferenceInMinutes,
      isAdmin: true,
    };

    try {
      const response = await api.post(apiEndpoint.signIn, data);
      if (response.data.responseStatus === 1) {
        setLoginError("");

        localStorage.setItem("token", response.data.userDetails?.accessToken);
        const nameArr = response.data.userDetails.name.split(" ");
        const formData = {
          name: nameArr[0],
          age: response.data.userDetails.age,
          gender: response.data.userDetails.gender,
        };
        localStorage.setItem("formData", JSON.stringify(formData));

        if (pathname === "/home/login") {
          navigation("/");
        } else {
          navigation("/home");
        }
      } else {
        setLoginError("Invalid email or password. Please try again."); 
      }
    } catch (error) {
      console.error("Login failed:", error);

      setLoginError(
        "Login failed due to server error. Please try again later."
      );
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="login-form-container">
          <Link to="/" className="logo-container">
            <img src={logo} alt="logo" className="logo" />
            <p className="logo-text">SUNNDIO Health</p>
          </Link>
          <div className="welcome-message">
            <p>Welcome</p>
          </div>
          <div className="login-with">
            <p>Login with</p>
          </div>

          {loginError && <div className="error-message">{loginError}</div>}

          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleLogin}
          >
            {({ isSubmitting }) => (
              <Form className="login-form">
                <div className="form-fields">
                  <label htmlFor="email">Email Address</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="input-field"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />

                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="input-field"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />

                  <p className="forgot-password">
                    <Link to={forgotPasswordpath}>Forgot Password?</Link>
                  </p>

                  <Button text="Login" disabled={isSubmitting} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;
