import React from 'react'

const HomePage = () => {
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", position:"absolute", top: "30%", left: "30%"}}>
        <img className='mx-5' src="/logo.png" alt="" style={{width: "150px", height: "150px"}} />
        <h1>
            Sunndio Health
        </h1>
    </div>
  )
}

export default HomePage
