import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import Button from "../../AuthButton/Button";
import { useState } from "react";
import { api } from "../../../api";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../../api/apiEndpoints";
import './login.css';
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const { pathname } = useLocation();
    const navigation = useNavigate();
    const [email, setEmail] = useState("");
    const [forgotPasswordError, setForgotPasswordError] = useState(""); 
    const [isLoading, setIsLoading] = useState(false); // Step 1: Introduce loading state

    const handleEmailSend = async (e) => {
        e.preventDefault();
        setIsLoading(true); 
        const data = {
            email: email.toLowerCase(),
        };

        try {
            const response = await api.post(apiEndpoint.forgotPassword, data);
            if (response.data.responseStatus === 1) {
                setForgotPasswordError("");
                toast.success("OTP sent to your email");
localStorage.setItem("userId",response?.data?.userId)
                if (pathname === "/forgotPassword") {
                    navigation("/updatePassword");
                } else {
                    navigation("/forgotPassword");
                }
            } else {
                setForgotPasswordError(response.data.error || "Failed to send OTP. Please try again."); 
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error("Forgot password failed:", error);
            setForgotPasswordError("An error occurred while sending the OTP. Please try again later.");
        } finally {
            setIsLoading(false); // Reset loading state after the operation
        }
    };

    return (
        <>
            <div className="login-container">
                <div className="login-form-container">
                    <Link to="/" className="logo-container">
                        <img src={logo} alt="logo" className="logo" />
                        <p className="logo-text">SUNNDIO Health</p>
                    </Link>
                    <div className="welcome-message">
                        <p>Did you forget your password?</p>
                    </div>
                    <div className="login-with">
                        <p>Please enter your email address for the verification process. We will send you a 4-digit code.</p>
                    </div>

                    {forgotPasswordError && <div className="error-message">{forgotPasswordError}</div>}

                    <form onSubmit={handleEmailSend} className="login-form">
                        <div className="form-fields">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="input-field"
                                required
                            />
                            <Button text="Continue" isLoading={isLoading} /> {/* Pass isLoading to Button */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
