import React from 'react';
import "./button.scss"

const Button = ({ text, onClick, isLoading, className }) => {

    return (
        <>
            <button
                type="submit"
                disabled={isLoading}
                className="ButtonLogin"
            >
                <p className='ButtonTextLogin'>{text.toUpperCase()}</p>
            </button>
        </>
    )
}

export default Button
