import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { api } from "../../../api";
import { apiEndpoint } from "../../../api/apiEndpoints";
import Button from "../../AuthButton/Button";
import { toast } from "react-toastify";
import logo from "../../../assets/images/logo.svg";
import './login.css';

const UpdatePassword = () => {
    const navigation = useNavigate();
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [reEnterNewPassword, setReEnterNewPassword] = useState("");
    const [otpError, setOtpError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Step 1: Introduce loading state

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setOtpError("");
        setPasswordError("");
        setIsLoading(true); // Step 2: Set loading state to true

        if (!otp) {
            setOtpError("This field is required");
            setIsLoading(false); // Reset loading state if there's an error
        } else if (otp.length < 4) {
            setOtpError("Otp must have 4 digits");
            setIsLoading(false); // Reset loading state if there's an error
        } else if (!newPassword || !reEnterNewPassword) {
            setPasswordError("Both fields are required");
            setIsLoading(false); // Reset loading state if there's an error
        } else if (newPassword !== reEnterNewPassword) {
            setPasswordError("Passwords do not match");
            setIsLoading(false); // Reset loading state if there's an error
        } else {
            const data = {
                userId: localStorage.getItem("userId"),
                newPassword,
                otpNumber: otp,
            };

            try {
                const response = await api.post(apiEndpoint.updateForgotPassword, data);

                if (response.data.responseStatus === 1) {
                    localStorage.removeItem("userId")
                    navigation("/login");
                } else {
                    toast.error(response.data.error);
                }
            } catch (error) {
                toast.error("An error occurred while updating the password.");
            } finally {
                setIsLoading(false); // Reset loading state after the operation
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-form-container">
                <Link to="/" className="logo-container">
                    <img src={logo} alt="logo" className="logo" />
                    <p className="logo-text">SUNNDIO Health</p>
                </Link>
                <div className="login-with">
                    <p>Please enter your 4-digit verification code.</p>
                </div>
                
                <form onSubmit={handleUpdatePassword} className="login-form">
                    <div className="form-fields">
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="input-field"
                        />
                        {otpError && <p className="error-message">{otpError}</p>}
                        
                        <input
                            name="newPassword"
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="input-field"
                        />
                        
                        <input
                            name="reEnterNewPassword"
                            type="password"
                            placeholder="Re-enter New Password"
                            value={reEnterNewPassword}
                            onChange={(e) => setReEnterNewPassword(e.target.value)}
                            className="input-field"
                        />
                        
                        {passwordError && <p className="error-message">{passwordError}</p>}
                        
                        <Button text="Continue" isLoading={isLoading} /> {/* Pass isLoading to Button */}
                    </div>
                </form>
                
            </div>
        </div>
    );
};

export default UpdatePassword;
